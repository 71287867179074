import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconStatistics = (props: IProps) => (
  <svg
    style={{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }}
    viewBox="0 0 88 88"
    fill="none"
  >
    <path
      d="M15 70C15 71.6569 16.3431 73 18 73H70C71.6569 73 73 71.6569 73 70C73 68.3431 71.6569 67 70 67H21L21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18L15 70Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5 40.5C36.7091 40.5 38.5 42.2909 38.5 44.5V57.5C38.5 59.7091 36.7091 61.5 34.5 61.5H30.5C28.2909 61.5 26.5 59.7091 26.5 57.5V44.5C26.5 42.2909 28.2909 40.5 30.5 40.5H34.5Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5 29.5C53.7091 29.5 55.5 31.2909 55.5 33.5V57.5C55.5 59.7091 53.7091 61.5 51.5 61.5H47.5C45.2909 61.5 43.5 59.7091 43.5 57.5V33.5C43.5 31.2909 45.2909 29.5 47.5 29.5H51.5Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.5 16.5C70.7091 16.5 72.5 18.2909 72.5 20.5V57.5C72.5 59.7091 70.7091 61.5 68.5 61.5H64.5C62.2909 61.5 60.5 59.7091 60.5 57.5V20.5C60.5 18.2909 62.2909 16.5 64.5 16.5H68.5Z"
      fill={props.color}
    />
  </svg>
)

export default IconStatistics
